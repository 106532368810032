import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
} from "@/components/ui/alert-dialog";
import { ScrollArea } from "@/components/ui/scroll-area";
import { pastBoxesQuery } from "./queries";
import { useState, useMemo } from "react";
import { Loading } from "@/components";
import { cn } from "@/lib/utils";
import { ArrowRightIcon } from "lucide-react";
import { ArrowLeftIcon } from "lucide-react";

type PastBoxesProps = {
  subscriptionId: number;
  onClose: () => void;
};

const PastBoxes = ({ subscriptionId, onClose }: PastBoxesProps) => {
  const [week, setWeek] = useState(1);
  const { data: response, isLoading } = pastBoxesQuery({
    subscriptionId: subscriptionId,
    week,
  });
  const data = response?.data;

  const randomizedProducts = useMemo(() => {
    if (!data?.products) return [];
    return [...data.products].sort(() => Math.random() - 0.5);
  }, [data?.products]);

  const renderContent = () => {
    return (
      <AlertDialogHeader>
        <AlertDialogDescription>
          <div className="p-2 border-t border-l border-r rounded-t-md rounded-b-none bg-primary text-white flex justify-between items-center">
            <p>
              {isLoading ? (
                "📦"
              ) : (
                <span>
                  📦{" "}
                  <strong className="font-semibold">
                    {data?.subscription_name}
                  </strong>{" "}
                  de hace{" "}
                  <strong className="font-semibold">
                    {week} {week === 1 ? "semana" : "semanas"}
                  </strong>
                </span>
              )}
            </p>
            <div className="flex items-center gap-2">
              <button
                type="button"
                onClick={() => setWeek((w) => Math.min(w + 1, 5))}
                disabled={week === 5}
                className={cn(
                  "text-white cursor-pointer",
                  week === 5 && "opacity-50",
                )}
              >
                <ArrowLeftIcon className="w-4 h-4" />
              </button>
              <button
                type="button"
                onClick={() => setWeek((w) => Math.max(w - 1, 1))}
                disabled={week === 1}
                className={cn(
                  "text-white cursor-pointer",
                  week === 1 && "opacity-50",
                )}
              >
                <ArrowRightIcon className="w-4 h-4" />
              </button>
            </div>
          </div>
          <div className="relative">
            <ScrollArea className="h-[300px] border rounded-md p-2">
              <div className="flex flex-col gap-2">
                {randomizedProducts.map((p) => (
                  <div key={p.id} className="border-b p-2 last:border-b-0">
                    <p className="text-sm text-muted-foreground">
                      <span className="font-semibold font-sans">
                        {p.quantity}
                        {p.quantity_unit_type}
                      </span>{" "}
                      - {p.full_product_name}
                    </p>
                  </div>
                ))}
              </div>
            </ScrollArea>
            {isLoading && (
              <div className="absolute inset-0 bg-white/20 flex items-center justify-center">
                <div>
                  <Loading message="Buscando información de cajas..." />
                </div>
              </div>
            )}
          </div>
        </AlertDialogDescription>
      </AlertDialogHeader>
    );
  };

  return (
    <AlertDialog open>
      <AlertDialogContent>
        <div>
          <h5 className="text-lg font-semibold mb-0">
            Cajas de las ultimas 5 semanas
          </h5>
          <p className="text-sm text-muted-foreground">
            Cada semana podrás modificar el contenido de la caja de acuerdo a tu
            gusto y comprar productos extras sin costo de envío extra ✨.
          </p>
        </div>
        {renderContent()}
        <AlertDialogFooter>
          <AlertDialogCancel onClick={onClose}>Cerrar</AlertDialogCancel>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default PastBoxes;
