import { Link } from "@tanstack/react-router";
import maifudLogo from "@/assets/maifud_logo.png";
import { MenuIcon } from "lucide-react";
import { Sheet, SheetContent, SheetHeader } from "@/components/ui/sheet";
import { useState, useEffect } from "react";
import { Separator } from "@/components/ui/separator";
import { useNavigate } from "@tanstack/react-router";
import { logout } from "@/firebase";
import { Skeleton } from "@/components/ui/skeleton";
import type { User } from "firebase/auth";
import Banner from "./Banner";
import { ButtonCustomLink } from "@/components";
import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  NavigationMenuTrigger,
} from "@/components/ui/navigation-menu";
import { cn } from "@/lib/utils";

const MENU_ITEMS = [
  { href: "#boxes-section", title: "Nuestras cajas", emoji: "📦" },
  { href: "#how-it-works-section", title: "¿Como funciona?", emoji: "💡" },
  { href: "#maifud-lovers-section", title: "MaifudLovers", emoji: "🧡" },
  { href: "#faq-section", title: "Preguntas frecuentes", emoji: "❓" },
];

type HeaderProps = {
  discountFormatted?: string;
  user: User | null;
  isLoadingUser: boolean;
};

const Header = ({ discountFormatted, user, isLoadingUser }: HeaderProps) => {
  const navigate = useNavigate();
  const [sectionsIsOpen, setSectionsIsOpen] = useState(false);
  const [_, setPosition] = useState(window.scrollY);
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      setPosition((prevPosition) => {
        const moving = window.scrollY;
        if (moving < 0) {
          return prevPosition;
        }
        setVisible(prevPosition > moving);
        return moving;
      });
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleLogout = () => {
    logout().finally(() => {
      navigate({
        to: "/",
      });
    });
  };

  const shouldRenderBanner =
    !isLoadingUser && user == null && discountFormatted;

  const renderUser = () => {
    if (isLoadingUser) {
      return <Skeleton className="text-sm w-[76px] h-[19px]" />;
    }

    return (
      <>
        {user?.email ? (
          <div className="hidden md:flex flex-row space-x-4 items-center">
            <ButtonCustomLink to="/user/subscription">
              Tu suscripción
            </ButtonCustomLink>
          </div>
        ) : (
          <div className="hidden md:flex flex-row space-x-4 items-center">
            <Link
              to="/login"
              className="hover:text-primary transition-colors duration-300 ease-in-out"
            >
              Ir a tu suscripción
            </Link>
            <ButtonCustomLink to="/register/subscriptions">
              Suscribirse
            </ButtonCustomLink>
          </div>
        )}
      </>
    );
  };

  return (
    <>
      <div
        className={cn(
          "fixed w-full z-50 bg-white",
          visible
            ? "top-0 transition-all duration-300"
            : "-top-48 transition-all duration-300",
        )}
      >
        {shouldRenderBanner && <Banner discountFormatted={discountFormatted} />}
        <header className="max-w-7xl mx-auto py-4 px-6 lg:px-16 flex justify-between items-center text-base">
          <Link to={"/"} className="flex items-baseline">
            <img alt="Maifud Logo" src={maifudLogo} className=" w-32 lg:w-44" />
          </Link>
          <div className="hidden lg:flex flex-row items-center">
            <NavigationMenu className="flex flex-row items-center space-x-4">
              <NavigationMenuList className="px-0">
                <NavigationMenuItem>
                  <NavigationMenuTrigger className="text-base font-normal px-0">
                    Sobre Maifud
                  </NavigationMenuTrigger>
                  <NavigationMenuContent>
                    <div className="flex flex-col space-y-2 p-4 min-w-[200px]">
                      <NavigationMenuLink>
                        <a
                          href="https://tienda.maifud.cl/nosotros"
                          target="_blank"
                          className="hover:text-primary transition-colors duration-300 ease-in-out block w-full"
                        >
                          Nosotros
                        </a>
                      </NavigationMenuLink>
                      <NavigationMenuLink>
                        <a
                          href="https://tienda.maifud.cl/el-problema"
                          target="_blank"
                          className="hover:text-primary transition-colors duration-300 ease-in-out block w-full"
                        >
                          El problema
                        </a>
                      </NavigationMenuLink>
                      <NavigationMenuLink>
                        <a
                          href={`#boxes-section`}
                          className="hover:text-primary transition-colors duration-300 ease-in-out block w-full"
                        >
                          Nuestras cajas
                        </a>
                      </NavigationMenuLink>
                      <NavigationMenuLink>
                        <a
                          href={`#faq-section`}
                          className="hover:text-primary transition-colors duration-300 ease-in-out block w-full"
                        >
                          Q&A
                        </a>
                      </NavigationMenuLink>
                    </div>
                  </NavigationMenuContent>
                </NavigationMenuItem>
              </NavigationMenuList>
              <div>
                <a
                  href="https://tienda.maifud.cl/blog"
                  target="_blank"
                  className="hover:text-primary transition-colors duration-300 ease-in-out"
                >
                  Blog
                </a>
              </div>
              <div>
                <a
                  href="https://tienda.maifud.cl/productos"
                  target="_blank"
                  className="hover:text-primary transition-colors duration-300 ease-in-out"
                >
                  Tienda
                </a>
              </div>
              {renderUser()}
            </NavigationMenu>
          </div>
          <div
            className="flex flex-col items-center cursor-pointer lg:hidden"
            onClick={() => setSectionsIsOpen(true)}
          >
            <MenuIcon size={32} className="text-muted-foreground" />
          </div>
          <Sheet open={sectionsIsOpen}>
            <SheetContent
              onPointerDownOutside={() => setSectionsIsOpen(false)}
              onClose={() => setSectionsIsOpen(false)}
            >
              <SheetHeader>
                <img alt="Maifud Logo" src={maifudLogo} className="w-32" />
              </SheetHeader>

              <h2 className="text-2xl font-bold tracking-tight mt-6 mb-2">
                ¡Hola! 👋
              </h2>

              {user?.email ? (
                <>
                  <ul className="flex flex-col mb-2">
                    <li
                      className="w-full"
                      onClick={() => setSectionsIsOpen(false)}
                    >
                      <Link
                        to="/user/subscription"
                        className="px-0 py-2 block text-left underline"
                      >
                        Ir a tu suscripción
                      </Link>
                    </li>
                    <li
                      className="w-full"
                      onClick={() => setSectionsIsOpen(false)}
                    >
                      <button
                        type="button"
                        className="px-0 py-2 block text-left underline"
                        onClick={handleLogout}
                      >
                        Cerrar sesión
                      </button>
                    </li>
                  </ul>
                  <Separator className="mb-2" />
                </>
              ) : (
                <>
                  <ul className="flex flex-col mb-2">
                    <li
                      className="w-full"
                      onClick={() => setSectionsIsOpen(false)}
                    >
                      <Link
                        to="/login"
                        className="px-0 py-2 block text-left underline"
                      >
                        Iniciar sesión
                      </Link>
                    </li>
                  </ul>
                  <Separator className="mb-2" />
                </>
              )}

              <div className="relative">
                <h2 className="text-xl font-bold tracking-tight mt-4">
                  <div>Suscripción</div>
                </h2>

                <ul className="flex flex-col mb-2">
                  {MENU_ITEMS.map(({ href, title }) => (
                    <li
                      className="w-full"
                      onClick={() => setSectionsIsOpen(false)}
                      key={title}
                    >
                      <Link
                        key={href}
                        to={href}
                        className="px-0 py-1 block text-left rounded-md"
                      >
                        <span className="underline">{title}</span>
                      </Link>
                    </li>
                  ))}
                </ul>
                <Separator className="mb-4" />
                <h2 className="text-xl font-bold tracking-tight mt-4 mb-2">
                  <div>Más sobre Maifud</div>
                </h2>
                <ul className="space-y-2">
                  <li>
                    <a
                      className="underline"
                      href="https://tienda.maifud.cl/el-problema"
                    >
                      El problema
                    </a>
                  </li>
                  <li>
                    <a
                      className="underline"
                      href="https://tienda.maifud.cl/nosotros"
                    >
                      Nosotros
                    </a>
                  </li>
                  <li>
                    <a
                      className="underline"
                      href="https://tienda.maifud.cl/despacho"
                    >
                      Despachos
                    </a>
                  </li>
                  <li>
                    <a
                      className="underline"
                      href="https://tienda.maifud.cl/blog"
                    >
                      Blog
                    </a>
                  </li>
                  <li>
                    <a
                      className="underline"
                      href="https://tienda.maifud.cl/productos"
                    >
                      Tienda granel
                    </a>{" "}
                    🍊
                  </li>
                </ul>
              </div>
            </SheetContent>
          </Sheet>
        </header>
      </div>
    </>
  );
};

export default Header;
