import { CustomLink } from "@/components";

type BannerProps = {
  discountFormatted: string;
};

const Banner = ({ discountFormatted }: BannerProps) => {
  return (
    <CustomLink to="/register/subscriptions">
      <div className="text-center py-4 bg-orange-500/20">
        <p>
          🔔
          <span className="pl-1 underline">
            Tienes un{" "}
            <strong className="text-primary font-sans">
              {discountFormatted}
            </strong>{" "}
            de descuento en tu primera caja de suscripción.
          </span>
        </p>
      </div>
    </CustomLink>
  );
};

export default Banner;
